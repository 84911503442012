import React, { useState, useCallback, useEffect } from 'react';
import { S3Client, CreateMultipartUploadCommand, UploadPartCommand, CompleteMultipartUploadCommand, AbortMultipartUploadCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import { Button, LinearProgress, Snackbar, Typography, Box, CircularProgress, Paper } from '@mui/material';
import { Alert } from '@mui/material';

const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;
const S3_REGION = process.env.REACT_APP_S3_REGION;

const s3Client = new S3Client({
  region: S3_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const PART_SIZE = 100 * 1024 * 1024; // 100MB part size
const MULTIPART_THRESHOLD = 5 * 1024 * 1024; // 5MB threshold for multipart upload

const getS3Key = (fileName, urlParam) => `temp/${urlParam}/${fileName}`;

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

function App() {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });
  const [urlParam, setUrlParam] = useState('');

  useEffect(() => {
    const path = window.location.pathname;
    const param = path.split('/').pop();
    setUrlParam(param || '');
  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setProgress(0);
    setUploadComplete(false);
  };

  const showNotification = useCallback((message, severity) => {
    setNotification({ open: true, message, severity });
  }, []);

  const uploadFile = async () => {
    if (!file) {
      showNotification('Please select a file first.', 'warning');
      return;
    }
  
    setUploading(true);
    setProgress(0);
    const s3Key = getS3Key(file.name, urlParam);
    let uploadId;
    let uploadedParts = [];
  
    try {
      if (file.size < MULTIPART_THRESHOLD) {
        // Simple upload for small files
        const uploadCommand = new PutObjectCommand({
          Bucket: S3_BUCKET_NAME,
          Key: s3Key,
          Body: file,
        });
  
        setProgress(50);
        await s3Client.send(uploadCommand);
        setProgress(100);
      } else {
        // Multipart upload for large files
        // Step 1: Initiate multipart upload
        const createMultipartUploadCommand = new CreateMultipartUploadCommand({
          Bucket: S3_BUCKET_NAME,
          Key: s3Key,
        });
  
        const multipartUpload = await s3Client.send(createMultipartUploadCommand);
        uploadId = multipartUpload.UploadId;
  
        // Step 2: Upload parts
        const numParts = Math.ceil(file.size / PART_SIZE);
  
        for (let i = 0; i < numParts; i++) {
          const start = i * PART_SIZE;
          const end = Math.min(start + PART_SIZE, file.size);
          const chunk = file.slice(start, end);
  
          const partNumber = i + 1;
          const uploadPartCommand = new UploadPartCommand({
            Bucket: S3_BUCKET_NAME,
            Key: s3Key,
            UploadId: uploadId,
            PartNumber: partNumber,
            Body: chunk,
          });
  
          const response = await s3Client.send(uploadPartCommand);
          uploadedParts.push({
            ETag: response.ETag,
            PartNumber: partNumber
          });
          setProgress((i + 1) / numParts * 90);
        }
  
        // Step 3: Complete multipart upload
        const completeMultipartUploadCommand = new CompleteMultipartUploadCommand({
          Bucket: S3_BUCKET_NAME,
          Key: s3Key,
          UploadId: uploadId,
          MultipartUpload: { Parts: uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber) },
        });
  
        await s3Client.send(completeMultipartUploadCommand);
        setProgress(100);
      }
  
      showNotification('Upload successful!', 'success');
      setUploadComplete(true);
    } catch (error) {
      console.error('Error uploading file:', error);
      let errorMessage = 'Upload failed';
      if (error.name === 'MalformedXML') {
        errorMessage += ': There was an issue with the request format.';
        console.error('Request details:', {
          Bucket: S3_BUCKET_NAME,
          Key: s3Key,
          UploadId: uploadId,
        });
        console.error('Error details:', JSON.stringify(error, null, 2));
        console.error('Uploaded parts:', JSON.stringify(uploadedParts, null, 2));
      } else if (error.$metadata) {
        errorMessage += `: ${error.name} - ${error.$metadata.httpStatusCode}`;
      } else {
        errorMessage += `: ${error.message}`;
      }
      showNotification(errorMessage, 'error');
  
      // Abort the multipart upload if it was initiated
      if (file.size >= MULTIPART_THRESHOLD && uploadId) {
        try {
          const abortCommand = new AbortMultipartUploadCommand({
            Bucket: S3_BUCKET_NAME,
            Key: s3Key,
            UploadId: uploadId,
          });
          await s3Client.send(abortCommand);
        } catch (abortError) {
          console.error('Error aborting multipart upload:', abortError);
        }
      }
      setUploadComplete(true);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f0f0f0' }}>
      <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: 600 }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
          acTVe File Uploader
        </Typography>
        
        {urlParam ? (
          <>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
              Upload folder: {urlParam}
            </Typography>
            
            <Box sx={{ mb: 3 }}>
              <input
                type="file"
                onChange={handleFileChange}
                disabled={uploading}
                style={{ display: 'none' }}
                id="raised-button-file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="outlined" component="span" fullWidth disabled={uploading}>
                  Choose File
                </Button>
              </label>
            </Box>
            
            {file && (
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Selected file: {file.name} ({formatFileSize(file.size)})
              </Typography>
            )}
            
            <Button 
              variant="contained" 
              color="primary" 
              onClick={uploadFile} 
              disabled={!file || uploading || uploadComplete} 
              fullWidth
              sx={{ mb: 3 }}
            >
              {uploading ? <CircularProgress size={24} color="inherit" /> : 'Upload'}
            </Button>
            
            {progress > 0 && (
              <Box sx={{ mb: 3 }}>
                <LinearProgress variant="determinate" value={progress} sx={{ mb: 1 }} />
                <Typography variant="body2" color="text.secondary" align="center">
                  {`${Math.round(progress)}%`}
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <Typography variant="body1" color="text.secondary" align="center">
            Please provide a valid URL parameter to access the upload feature.
          </Typography>
        )}
      </Paper>
      
      <Snackbar 
        open={notification.open} 
        autoHideDuration={6000} 
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setNotification(prev => ({ ...prev, open: false }))} 
          severity={notification.severity} 
          sx={{ width: '100%' }}
          variant="filled"
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default App;
